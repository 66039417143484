import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import ModernImg from "../components/ModernImg"
import SinglePageHeader from "../components/SinglePageLayout"

export default function Careers() {
  const { t } = useTranslation()

  return (
    <SinglePageHeader
      title={t("about-us")}
      bg="/assets/img/about-us-banner.jpg"
    >
      <section className="pd-top-100 ich-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title section-title-2 text-center">
                <h6 className="sub-title">{t("company")}</h6>
                <h2 className="title">{t("about-us")}</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pd-bottom-100">
            <div className="col-lg-5">
              <ModernImg src={"/assets/img/csr-banner.jpg"} alt="about us" />
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6">
              {t("about-us-text")}
              {t("about-us-last-p")}
            </div>
          </div>
        </div>
      </section>
      <section className="ich-wrapper pd-top-100 pd-bottom-100 bg-grey">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title section-title-2 text-center">
                <h6 className="sub-title">{t("ich-founding-team")}</h6>
                <h2 className="title">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/posts/danny-toe_capitalmarkets-startups-singapore-activity-6980708308827475969-8EXN?utm_source=share&utm_medium=member_desktop"
                  >
                    {t("about-us-chairman")}
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="">
            <p>{t("about-us-chairman-p1")}</p>
            <p>{t("about-us-chairman-p2")}</p>
            <p>{t("about-us-chairman-p3")}</p>
            <p>{t("about-us-chairman-p4")}</p>
          </div>
        </div>
      </section>
    </SinglePageHeader>
  )
}
